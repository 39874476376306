export default {
  "about": {
      "title": "Qui sommes nous?",
      "left": "Source de connaissances pour votre développement professionnel",
      "right": "Chacun a la force et le talent nécessaires pour atteindre des objectifs majeurs. Nous vous aidons à trouver une voie de développement et à réaliser votre potentiel professionnel, comme vous le voulez",
      "author": {
          "title": "Un mot sur l'auteure",
          "btn":  "Regarder l'histoire"
      },
      "breadCrumbs": {
          "home":  "Accueil",
          "about": "Qui sommes nous?"
      }
  },
  "basket": {
      "title":  "Votre panier",
      "date": {
          "postcode": "Code Postal",
          "title": "Introduisez vos données",
          "name":  "Prénom",
          "surname":  "Nom",
          "phone":  "Numéro de téléphone",
          "city": "Ville",
          "district":  "Département",
          "houseNumber":  "Numéro dans la voie",
          "email":  "Adresse e-mail",
          "addressInvoice":  "Adresse de facturation",
          "country":  "Pays",
          "street":  "Rue",
          "flat":  "Appartement",
          "person":  "Personne physique",
          "company":  "Personnalité juridique",
          "companyName":  "Nom de l'entreprise",
          "fiscalCode": "Numéro d'identification fiscale",
          "tradeNumber":  "Numéro RCS",
          "companyAddress":  "L'adresse de l'entreprise",
          "block": "Bloquer"
      },
      "delivery": {
          "title":  "Livraison",
          "courier":  "Livraison",
          "shop":  "Retrait dans le magasin",
          "courierDesc": "Livraison dans le département ou à domicile",
          "courierDescRo": "Livraison GRATUITE si le prix de la commande est supérieur à 500 lei",
          "shopDesc": "Pour ceux qui veulent nous rendre visite",
          "address":  "L'adresse complète",
          "info": "Livraison gratuite : Chișinău - pour commandes supérieures à 1200 lei ; Régions - pour commandes supérieures à 1500 lei. Dans tous les autres cas, les frais de l'envoi seront assurés par le client(les frais de l'envoi varient en fonction du poids du colis et de l'adresse de livraison)",
          "novaPosta": "Chișinău - pour commandes supérieures à 1200 lei ; Régions - pour commandes supérieures à 1500 lei.",
          "selectType": {
              "courier":  "Coursier",
              "inOffice": "Depuis le bureau Nova Poshta"
          }
      },
      "street": {
          "houseNumber":  "Numéro", 
          "flat":  "Appartement"
      },
      "payment": {
          "title": "Modes de paiement",
          "сash":  "Cash",
          "card": "Carte bancaire"
      },
      "confirmare": {
          "title": "Confirmation",
          "comments":  "Commentaires",
          "promo":  "Code promotionnel",
          "orderAmount": "Somme",
          "delivery":  "Livraison",
          "total":  "Total",
          "finish":  "Finaliser la commande",
          "conditions":  "Je suis d'accord avec les Termes",
          "policy": "Politique de Confidentialité"
      },
      "useCashback": "Utiliser les points de fidélité",
      "noProducts" : {
        "title" : "Il n'y a rien dans le panier, mais c'est facile à changer",
        "btn": "Aller au boutique"
      } 
  },
  "category": {
      "titlePage": "Cours",
      "home":  "Accueil",
      "results":  "résultats",
      "reload":  "Chargez plus",
      "from":  "de",
      "advancedFiltering": "Plus de filtres"
  },
  "aboutAuthor":{
      "author": {
          "btn": "Regarder l'histoire"
      }
  },
  "cardTags":{
      "from": "de" 
  },
  "contactForm":{
      "form": {
          "title": { "text1": "Parlez-nous", "color": "de vos", "text2":  "objectifs" },
          "desc": "Nous allons  vous aider à choisir le programme adapté à vos buts.", 
          "consent": { "text1": "Je suis d'accord avec", "link": "les Termes et les Conditions", "text2": "de traitement des données à caractère personnel." },
          "country": "Pays",
          "name":  "Nom",
          "phone": "Numéro de téléphone",
          "btn": "Laisser une demande",
          "message": "La demande a bien été envoyée et nous allons vous contacter dans les plus brefs délais."
      }
  },
  "footer":{
          "courses": "Cours",
    "shop":  "Boutique",
    "specialists": "Praticiens",
    "about":  "Qui sommes nous?",
          "blog":  "Blog",
    "login": "Créer un compte",
    "registration": "Inscription",
    "terms":  "Termes et Conditions",
    "provisions":  "Politique de Confidentialité",
    "message": "Laisser un message",
    "desc": "La première Académie de beauté, spécialisée en cosmétologie et médecine esthétique. Des professionnels de santé et des praticiens qualifiés vous offrent les meilleurs outils pour vous aider à atteindre vos objectifs." ,
    "policy":  "Guide d'utilisation",
          "security": "Politique de sécurité"
  },
      "header": {
          "courses": "Cours",
          "shop": "Boutique",
          "specialists": "Praticiens",
          "about": "Qui sommes nous?",
          "blog":  "Blog",
          "promotions":  "Offres promotionnelles",
          "login":  "Connexion",
          "registration":  "Inscription", 
          "shopHidden":  "uniquement pour les utilisateurs enregistrés",
          "shopClose":  "indisponible dans votre région",
          "dashboard": "Compte personnel"
      },
  "personalSlider":{
      "title": { "text": "Nous croyons au succès  ", "color": "de chacun!" }, 
      "btn": "Regarder l'histoire"
  },
  "course":{
      "btnPath": {
          "home":  "Accueil", 
          "category": "Cours"
      },
      "skillTitle": {
          "text": "Les avantages", 
          "color": "du cours"
      },
      "courseTitle": {
          "text": "Le contenu",
          "color":  "du cours"
      },
      "courseDesc": "Des cours en ligne, des exercices pratiques, des workshops et des master-class vous attendent",
      "chapters": "théorique",
      "modules": "vidéos pratiques",
      "priceTitle":  "Le prix du cours",
      "buy":  "Acheter le cours",
      "subscription":  "Boutique en ligne",
      "login": "Enregistrement immédiat",
      "SeeTheProgram": "Le contenu du cours",
      "modalBuyCourse": {
          "title":  "Acheter le cours",
          "name":  "Nom",
          "phone":  "Téléphone",
          "modeBuy":  "Modes de paiement",
          "modalBtnBuy":  "Acheter",
          "buy":  "Carte bancaire",
          "conditions":  "J'accepte les Termes et les Conditions",
          "terms": "Guide d'utilisation",
          "payment": "Paiement du cours"
      },
      "preview": "LE COURS VIDÉO EST ENREGISTRÉ, VOUS POUVEZ COMMENCER À APPRENDRE À TOUT MOMENT APRÈS L'ACHAT."
  },
  "courseInner":{
      "btnPath": {
          "home":  "Accueil",
          "category":  "Cours"
      },
      "courseMaterials": "Le programme du cours",
      "courseTitle": {
          "text":  "Le contenu",
          "color": "du cours"
      },
      "courseDesc": "Des cours en ligne, des exercices pratiques, des workshops et des master-class vous attendent",
      "chapters":  "théorique",
      "modules": "vidéos pratiques"
  },
  "dashboard":{
      "abonament":{
          "title":  "Cours",
    "purchasedCourses": "Cours achetés",
    "viewStatus": {
      "start":  "Non commencé",
      "mid":  "Non terminé",
      "final": "Terminé"
    },
    "btnPlay":  "Continuer"
      },
      "shop":{
          "title":  "Historique",
          "titleDesc":  "Toutes les opérations",
          "message":  "Vous n'avez pas de ventes pour l'instant, la liste de vos ventes sera affichée ici",
          "products": "Produits",
          "cashback": "Cashback"
      },
      "applications":{
          "title":  "Demandes",
    "list": "Liste",
    "calendar":  "Calendrier",
    "confirmation":  "Autoconfirmation",
    "table": {
      "title":  "Demandes confirmées",
      "name":  "Nom",
      "phone":  "Numéro de téléphone",
      "data":  "Date du rendez-vous",
      "endData": "Effectuer le rendez-vous"
    },
    "btn": {
      "title":  "Confirmer",
      "done":  "Annuler",
      "addUser": "Ajouter un rendez-vous"
    },
    "emptyMessage":  "Vous n'avez pas de nouveaux rendez-vous",
    "msgConfirmate": "Vous n'avez pas de demandes confirmées",
    "warnings": "La date et l'heure sélectionnées sont déjà occupées"
    }
  },
  "faq":{
      "title": {
          "text":  "Vous avez",
          "color":  "des questions?"
      },
      "desc": {
          "text":  "Ici vous pouvez trouver les réponses aux questions fréquemment posées",
          "color":  "ou nous contacter.",
          "text2":  "Nous allons vous accompagner tout au long de vos études."
      },
      "info": "Pour plus d’informations, veuillez nous contacter.",
      "btnText": "Laisser une demande"
  },
  "home":{
      "header": {
          "courses":  "Cours",
          "shop":  "Boutique",
          "specialists":  "Praticiens",
          "about":  "Qui sommes nous?",
          "blog":  "Blog",
          "promotions":  "Stock",
          "login":  "Connexion",
          "registration":  "Inscription", 
          "shopHidden": "uniquement pour les utilisateurs enregistrés",
          "shopClose":  "indisponible dans votre région"
      },
      "slider": {
  
          "btn": "Choisir un cours" 
      },
      "about": {
          "video": { "title": "Vidéo", "desc": "Cours vidéo et webinaires" }, 
          "support": { "title":  "Support", "desc": "Les conseils des meilleurs spécialistes" },
          "web": { "title": "WEB", "desc":  "L’avantage de créer votre page WEB personnelle et la possibilité d’enregistrer des rendez-vous"},
          "results": { "title": "Résultat", "desc": "Certificat de qualification"}
      },
      "support": {
          "question":  "Besoin d'aide?",
          "desc": "Nous serions ravis de répondre à toutes vos questions et de vous accompagner.",
          "btn": "Nous contacter"
      },
      "courses": {
          "title": "Nos cours",
          "left":  "Cosmétologie",
          "right": "Médecine esthétique",
          "btn":   "Découvrir tous les cours",
          "massage": "Massage",
      },
      "author": {
          "title": "Un mot sur l'auteure",
          "btn":  "Regarder l'histoire"
      },
      "offers": {
          "title": {
              "txt":  "Offres",
              "color": "promotionnelles"
          }
      }
  },
  "login":{
      "title": "Connexion",
      "name": "Login",
      "password":  "Mot de passe",
      "confirmPas":  "Confirmer le mot de passe",
      "newpass":  "Nouveau mot de passe",
      "login":  "Entrer dans le cabinet",
      "registration":  "Enregistrement",
      "recoveryPass":  "Récupérer",
      "btnRecover":  "Récupérer",
      "save":  "Mémoriser",
      "generate":  "Générer",
      "badDate": "Données non valables",
      "password2":  "Le mot de passe doit contenir 6 caractères",
      "forgotPass":  "Mot de passe oublié?",
      "message": "Veuillez vérifier votre boîte mail"
  },
  "registration":{
      "title":  "Inscription",
      "name":  "Prénom",
      "surname":  "Nom",
      "phone":  "Numéro de téléphone",
      "email":  "Adresse e-mail",
      "password":  "Mot de passe",
      "password2":  "Confirmer le mot de passe",
      "proffession": {
          "title":  "Spécialité",
          "cosmetolog":  "Esthéticien",
          "medic":  "Médecin",
          "other": "Pour les clients sans certificat d'esthéticienne"
      },
      "errors": {
          "password1" :  "Le mot de passe doit contenir 6 caractères",
          "password2" :  "Les mots de passe ne correspondent pas"

      },
      "strongPassword":  "Le mot de passe n'est pas assez fort",
      "messageCertificate":  "Le nom et le prénom seront affichés sur le certificat",
      "messageBusyUser": "l'adresse mail est déjà prise",
      "certificate":  "Télécharger le certificat",
      "login": "Connexion",
      "log_in": "Inscription",
      "politics":  "J'accepte les Termes et les Conditions",
      "politics2":  "J'accepte la Politique de Confidentialité",
      "popup":  "Votre inscription est vérifiée",
      "country":  "Pays"
  },
  "shop":{
      "links": {
          "home":  "Accueil",
          "shop":  "Boutique en ligne"
      },
      "title":  "Boutique en ligne",
      "filtreTitle":  "Filtrer les produits",
      "brand":  "Marque",
      "price": "Prix",
      "categorie": "Catégorie",
      "sorted":  "Filtrer",
      "result":  "résultats",
      "btnAdd":  "Ajouter",
      "loadPage":  "Chargez plus",
      "specialist":  "Praticiens"
  },
  "specialists":{
      "linkHome":  "Accueil",
      "title": "Praticiens",
      "btn":  "Prendre rendez-vous",
      "btn2":  "Boutique en ligne",
      "selectTitle": "Choisir la date du rendez-vous",
      "price": "Consultation",
      "reload":  "Chargez plus",
      "result":  "résultats",
      "search":  "Chercher",
      "address":  "Adresse",
      "certificateTitle": {
          "text":  "Certificats et ",
          "color": "diplômes"
      }
  },
  "certificates":{
      "title":  "Certificats Académie Lica.Doctor",
      "descTitle": "Liste de certificats reçus après avoir fait les cours sur la plateforme de l'Académie Lica.Doctor",
      "title2":  "Certificats personnels",
      "descTitle2":  "Liste des certificats et distinctions personnelles qui ne se réfèrent pas à l'Académie",
      "btnAdd":  "Ajouter un certificat",
      "messageCertificate":  "Vous n'avez pas encore de certificats",
      "messageCertificateLink":  "Accès à la page Cours",
      "warning":  "Attention",
      "confirmation":  "Effacer le Certificat ?",
      "delete": "Effacer" 
  },
  "changePass":{
      "mainTitle":  "Changer le mot de passe",
      "oldPass":  "Vieux mot de passe",
      "newPass":  "Nouveau mot de passe",
      "repeatPass":  "Répétez le mot de passe",
      "save": "Sauvegarder",
      "errMes1":  "Le mot de passe ne convient pas"
  },
  "courses":{
      "noAvailable": "Vous n'avez pas encore de cours, vous pouvez",
      "noAvailableLink":  "acheter le premier.",
      "free":  "Gratuit",
      "advantage": "Donner vie à vos idées",
      "price":  "0 MDL",
      "perMonth":  "/mois",
      "current":  "L'option en cours",
      "advantage1" : "Accès vidéo 10 heures/mois",
      "advantage2":  "Accès limité aux cours",
      "advantage3":  "Le programme des enseignements et des cours",
      "type":  "Pro",
      "advantage4": "Le meilleur pour les utilisateurs individuels",
      "price2":  "300 MDL",
      "buy":  "Acheter Pro",
      "advantage5": "Accès vidéo 36 heures/mois",
      "advantage6":  "Accès illimité aux cours",
      "work":  "Matériaux de travail",
      "resource": "Sources",
      "type2": "Pro+",
      "advantage8": "Pour de vrais professionnels", 
      "price3":  "1200 MDL",
      "buy2": "Acheter Pro+",
      "advantage10":  "Accès vidéo illimité",
      "advice":  "Conseils pratiques de la part des superviseurs"
  },
  "myPage":{
      "message":  "Compléter le champ",
      "title":  "Page personnelle",
      "certificates":  "Certificats personnels",
      "certificatesDesc":  "Liste des certificats affichés",
      "services":  "Mes services",
      "btnAdd": "Ajouter",
      "btnSave":  "Sauvegarder",
      "personalLink":  "Lien web personnel",
      "placeholder":{
          "name":  "Nom / Prénom",
          "speciality":  "Spécialité",
          "price":  "Prix",
          "address":  "Adresse"
      }
  },
  "orders":{
      "title": "Historique",
      "titleDesc":  "Toutes les opérations",
      "message":  "Vous n'avez pas encore de paiements, la liste de vos paiements sera affichée ici"
  },
  "protocols":{
      "message":  "Vous n'avez pas encore de protocoles, vous pouvez obtenir les protocoles en achetant le cours"
  },
  "requests":{
      "busy": "Occupé",
      "daysOfWeek":  ["Lu", "Ma", "Me", "Je", "Ve", "Sa", "Di"]
  },
  "article":{
      "home": "Accueil",
      "blog":  "Blog",
      "article": "Article",
      "back":  "Revenir",
      "readMore":  "Suivant"
  },
  "checkout":{
      "message":  "La commande a bien été enregistrée!",
      "messageDesc": "Vous allez bientôt recevoir un e-mail contenant les détails de votre commande"
  },
  "product":{
      "home":  "Accueil",
      "shop":  "Boutique en ligne",
      "add":  "Ajouter au panier",
      "added": "Ajouté" ,
      "sliderTitle": ["Produits", "similaires"],
      "returnPolicy": "Retour: Les produits cosmétiques qui présentent un emballage scellé ne peuvent être retournés que dans son emballage d'origine et non descellés." ,
      "inStock":  "Indisponible dans le stock"
  },
  "promotion":{
      "home":  "Accueil",   
      "breadcrumb1":  "Stock",
      "breadcrumb2": "Promotion",
      "back":  "Revenir en arrière"
  },
  "promotions":{
      "readMore":  "Suivant",
      "home": "Accueil",
      "breadcrumb1": "Promotions"
  },
  "usefulInfo":{
      "home":  "Accueil",
      "blog":  "Informations utiles",
      "article":  "Article",
      "back":  "Revenir en arrière"
  },
  "appoitment":{
      "title":  "Prendre rendez-vous",
      "namePhone": "Nom et numéros de téléphone",
      "comment":  "Commentaire",
      "name":  "Nom",
      "phone": "Numéro de téléphone",
      "btnModal":  "Sauvegarder",
      "message":  "Votre demande a été envoyée!"
  },
  "calendar":{
      "title":  "Modifier rendez-vous",
      "namePhone":  "Nom et numéros de téléphone",
      "name": "Nom",
      "phone":  "Numéro de téléphone",
      "timeDate":  "Date et heure",
      "comment":  "Commentaire",
      "btn":  "Sauvegarder"
  },
  "confirmation": {
      "text":  "Sauvegardé avec succès"
  },
  "politics":{
      "message": "Nous utilisons des cookies sur notre site web pour vous offrir la meilleure expérience possible adaptée à vos préférences, collectées lors de vos visites. En appuyant sur J'accepte vous donnez votre accord pour l'utilisation des cookies. Pour plus d'informations sur les cookies utilisés sur le site, veuillez consulter la page Gestion des cookies.",
      "btn":  "J'accepte",
      "link":  "la Politique des cookies"
  },
  "productSelect": "Rechercher un produit",
  "warnings": "Avertissements",
  "breadCrumbs": {
    "back": "Arrière"
  },
  coursesUpdatesModal: {
    title: "Avez-vous une nouvelle vidéo/protocole dans le cours",
    content: "En cours/cours <b>{courses}</b>, il y a des mises à jour dans la section vidéo/protocoles, cliquez sur \"Aller aux cours\" pour passer à la visualisation.",
    button: "Aller aux cours",
  }
}
